.leaflet-container {
  width: 100%;
  height: 100%;
}

#root {
  display: flex;
  flex-flow: column;
  height: 100%;
}

html, body {
  height: 100%;
}

.App {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.od-controls {
  z-index: 1000;
  position: absolute;
  right: 0;
  height: 90%;
  width: 20em;
  background-color: white;
  overflow: scroll;
}

.od-description {
  height: 10em;
  overflow: scroll;
}
